import $ from 'jquery';
import 'slick-carousel/slick/slick.min.js'; // Import Slick Carousel JS
import 'slick-carousel/slick/slick.css'; 
import { useEffect } from 'react';

const ApplyScripts=()=>{

    

};

export default ApplyScripts;